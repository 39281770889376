import { useElements, useStripe } from "@stripe/react-stripe-js";
import { useRouter } from "next/router";
import { useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { Button } from "@components/Button";
import { LeftAlignedTitle } from "@components/LeftAlignedTitle";
import { ReservationPointModal } from "@components/ReservationPointModal";
import { PredefinedToolTips } from "@components/ToolTip";
import { RequestReservationSteps } from "@components/pages/shops/[uuid]/request_reservation/RequestReservationSteps";
import { ReservationConfirmationCard } from "@components/pages/shops/[uuid]/request_reservation/confirm/ReservationConfirmationCard";
import { ReservationPaymentNewCard } from "@components/pages/shops/[uuid]/request_reservation/payment_methods/ReservasionPaymentNewCard";
import { ReservationPaymentOnSite } from "@components/pages/shops/[uuid]/request_reservation/payment_methods/ReservasionPaymentOnSite";
import { ReservationPaymentSavedCard } from "@components/pages/shops/[uuid]/request_reservation/payment_methods/ReservasionPaymentSavedCard";
import ReservationPaymentPointsAll from "@components/pages/shops/[uuid]/request_reservation/payment_methods/ReservationPaymentsPointAll";
import ReservationPaymentPointsNone from "@components/pages/shops/[uuid]/request_reservation/payment_methods/ReservationPaymentsPointNone";
import ReservationPaymentPointsSome from "@components/pages/shops/[uuid]/request_reservation/payment_methods/ReservationPaymentsPointSome";
import { ReservationPlanAllowedPaymentTypeEnum } from "@graphql/__generated__/types";
import Message, { PredefinedMessages } from "@hooks/useMessage";
import { useModalDialog } from "@hooks/useModalDialog";
import RequestReservation from "@hooks/useRequestReservation";
import { paths } from "@libs/paths";
import { toLocaleStr } from "@libs/utils/date";
import { rollbar } from "@libs/utils/rollbar";
import styles from "./index.module.css";
import type { PaymentMethods_PaymentMethodsQuery } from "@pages/shops/[uuid]/request_reservation/payment_methods/__generated__/queries";
import type {
  StripeError,
  StripePaymentElementChangeEvent,
} from "@stripe/stripe-js";
import type { FC } from "react";

export enum StaticPaymentMethodEnum {
  OnSite = "ON_SITE",
  NewCard = "NEW_CARD",
}

export type PaymentMethodInputType = {
  method: StaticPaymentMethodEnum | string | undefined; // "NEW_CARD" | "ON_SITE" | "stripePaymentMethodId(pm_***)" のどれかが入る。結局型はstringになってユニオンではないが、明示のためEnumも入れている
  savesNewCard: boolean;
  pointsToApplyType: "all" | "some" | "none";
  pointsToApply: number | undefined;
};

type Props = {
  paymentMethodsData: PaymentMethods_PaymentMethodsQuery;
};

export const MINIMUM_STRIPE_PAYMENT_AMOUNT = 50;

const buildDefaultValuesAroundPoints = (
  pointsToApplyFromContext: number | undefined,
  applicablePoints: number
): Pick<PaymentMethodInputType, "pointsToApplyType" | "pointsToApply"> => {
  if (
    pointsToApplyFromContext === undefined ||
    pointsToApplyFromContext === 0
  ) {
    return {
      pointsToApplyType: "none",
      pointsToApply: undefined,
    };
  }
  if (pointsToApplyFromContext === applicablePoints) {
    return {
      pointsToApplyType: "all",
      pointsToApply: undefined,
    };
  }
  return {
    pointsToApplyType: "some",
    pointsToApply: pointsToApplyFromContext,
  };
};

export const ReservationPaymentForm: FC<Props> = ({ paymentMethodsData }) => {
  const router = useRouter();
  const stripe = useStripe();
  const elements = useElements();
  const savedCards = paymentMethodsData.paymentCards;
  const userPoints = paymentMethodsData.currentUser.user?.points ?? 0;
  const pointModal = useModalDialog();

  const { addMessage } = Message.useContainer();

  const shopUuid =
    typeof router.query.uuid === "string" ? router.query.uuid : "";
  const { requestReservation, setRequestReservation, totalAmount } =
    RequestReservation.useContainer();
  const plan = requestReservation.reservationFrame?.reservationPlan;
  const applicablePoints =
    userPoints >= totalAmount
      ? totalAmount
      : Math.min(userPoints, totalAmount - MINIMUM_STRIPE_PAYMENT_AMOUNT);
  const appInstallUrl = paths.appOneLinkForRequestReservation(
    process.env.APP_HOST +
      paths.shopRequestReservation(shopUuid, {
        date: toLocaleStr(
          requestReservation.reservationFrame?.date ?? "",
          "ymdOnly"
        ),
        matchId: requestReservation.reservationFrame?.match?.id,
      })
  );

  const showPaymentMethods = (() => {
    switch (plan?.allowedPaymentType) {
      case ReservationPlanAllowedPaymentTypeEnum.Any:
        return {
          onSystem: true,
          onSite: true,
        };
      case ReservationPlanAllowedPaymentTypeEnum.OnSystem:
        return {
          onSystem: true,
          onSite: false,
        };
      case ReservationPlanAllowedPaymentTypeEnum.OnSite:
        return {
          onSystem: false,
          onSite: true,
        };
      default: {
        return {
          onSystem: false,
          onSite: false,
        };
      }
    }
  })();

  const methods = useForm<PaymentMethodInputType>({
    defaultValues: {
      method:
        requestReservation.paymentMethod ??
        (savedCards.items.length > 0
          ? savedCards.items[0].stripePaymentMethodId
          : StaticPaymentMethodEnum.NewCard),
      savesNewCard: false,
      ...buildDefaultValuesAroundPoints(
        requestReservation.pointsToApply,
        applicablePoints
      ),
    },
    mode: "onChange",
  });
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = methods;
  const selectedPaymentMethod = useWatch({ control, name: "method" });
  const selectedPointsToApplyType = useWatch({
    control,
    name: "pointsToApplyType",
  });
  const pointsToApply = useWatch({ control, name: "pointsToApply" });

  const isFullPointsPayment =
    (selectedPointsToApplyType === "all" && applicablePoints === totalAmount) ||
    (selectedPointsToApplyType === "some" &&
      Number(pointsToApply) === totalAmount);

  const [isNewCardValid, setIsNewCardValid] = useState(false);
  const isValid = (() => {
    if (isFullPointsPayment) {
      return true;
    }
    switch (selectedPaymentMethod) {
      case StaticPaymentMethodEnum.OnSite:
        return true;
      case StaticPaymentMethodEnum.NewCard:
        return isNewCardValid && !errors["pointsToApply"];
      default: {
        const selectedSavedCard = savedCards.items.find(
          (card) => card.stripePaymentMethodId === selectedPaymentMethod
        );
        return (
          selectedSavedCard &&
          !selectedSavedCard.isExpired &&
          !errors["pointsToApply"]
        );
      }
    }
  })();

  const handlePaymentElementChange = (
    event: StripePaymentElementChangeEvent
  ) => {
    setIsNewCardValid(event.complete);
  };

  const handleStripeError = (error: StripeError) => {
    switch (error.type) {
      case "validation_error":
        // バリデーションエラーの場合はフォームにメッセージが表示されるので何もしない
        return;
      default:
        addMessage(PredefinedMessages.commonError);
        rollbar?.error(error);
        return;
    }
  };

  const handleNextButton = async (input: PaymentMethodInputType) => {
    // 現地決済の場合
    if (input.method === StaticPaymentMethodEnum.OnSite) {
      setRequestReservation({
        ...requestReservation,
        paymentMethod: input.method,
        confirmationToken: undefined,
        creditCard: undefined,
        savesNewCard: false,
        pointsToApply: undefined,
      });
      router.push(paths.shopRequestReservationConfirm(shopUuid));
      return;
    }

    // 全額ポイント支払いの場合
    if (isFullPointsPayment) {
      setRequestReservation({
        ...requestReservation,
        paymentMethod: undefined,
        confirmationToken: undefined,
        creditCard: undefined,
        savesNewCard: false,
        pointsToApply: totalAmount,
      });
      router.push(paths.shopRequestReservationConfirm(shopUuid));
      return;
    }

    const pointsToApply = (() => {
      switch (input.pointsToApplyType) {
        case "all":
          return applicablePoints;
        case "some":
          return Number(input.pointsToApply);
        case "none":
          return userPoints > 0 ? 0 : undefined;
        default:
          return undefined;
      }
    })();

    // 保存済みのカードの場合
    if (input.method !== StaticPaymentMethodEnum.NewCard) {
      const creditCard = savedCards.items.find(
        (card) => card.stripePaymentMethodId === input.method
      );
      setRequestReservation({
        ...requestReservation,
        paymentMethod: input.method,
        confirmationToken: undefined,
        creditCard,
        savesNewCard: false,
        pointsToApply,
      });
      router.push(paths.shopRequestReservationConfirm(shopUuid));
      return;
    }

    // 新規カードの場合
    if (!stripe || !elements) {
      return;
    }

    const { error: submitError } = await elements.submit();
    if (submitError) {
      handleStripeError(submitError);
      return;
    }
    const { error, confirmationToken } = await stripe.createConfirmationToken({
      elements,
      params: {
        payment_method_data: {
          billing_details: {
            address: {
              country: "JP",
            },
          },
        },
      },
    });

    if (error) {
      handleStripeError(error);
      return;
    }

    setRequestReservation({
      ...requestReservation,
      paymentMethod: input.method,
      confirmationToken: confirmationToken.id,
      creditCard: confirmationToken?.payment_method_preview.card,
      savesNewCard: input.savesNewCard,
      pointsToApply,
    });
    router.push(paths.shopRequestReservationConfirm(shopUuid));
    return;
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleNextButton)}>
        <RequestReservationSteps step={2} showsStep2={true} />
        <div className={styles.column}>
          <ReservationConfirmationCard />
          {selectedPaymentMethod !== StaticPaymentMethodEnum.OnSite && (
            <section className={styles.formSection}>
              <div className={styles.title}>
                <LeftAlignedTitle label={"Fanstaポイントの利用"} />
              </div>

              <div className={styles.availablePoint}>
                <div className={styles.availablePointLabel}>保有ポイント</div>
                <div className={styles.availablePointNumber}>
                  <em>{userPoints.toLocaleString()}</em> pt
                </div>
              </div>

              <div className={styles.rows}>
                <div className={styles.note}>
                  お支払いの一部にポイントを利用する場合は、決済金額を50円未満にできません。
                </div>

                {userPoints > 0 && (
                  <>
                    <ReservationPaymentPointsAll
                      applicablePoints={applicablePoints}
                    />
                    <ReservationPaymentPointsSome
                      applicablePoints={applicablePoints}
                      paymentAmount={totalAmount}
                    />
                  </>
                )}
                <ReservationPaymentPointsNone />
              </div>
            </section>
          )}

          {!isFullPointsPayment && (
            <section className={styles.formSection}>
              <div className={styles.title}>
                <LeftAlignedTitle
                  label={"お支払い方法"}
                  toolTip={PredefinedToolTips.reservationPaymentMethods}
                />
              </div>

              <div className={styles.rows}>
                <div className={styles.note}>
                  すべての取引は安全で、暗号化されています。
                </div>

                <h2 className={styles.label}>事前決済</h2>
                {savedCards.items.map((card) => (
                  <ReservationPaymentSavedCard
                    card={card}
                    key={card.stripePaymentMethodId}
                  />
                ))}
                {showPaymentMethods.onSystem && savedCards.canSaveMoreCards && (
                  <ReservationPaymentNewCard
                    handlePaymentElementChange={handlePaymentElementChange}
                    appInstallUrl={appInstallUrl}
                  />
                )}
                {showPaymentMethods.onSite && (
                  <>
                    <h2 className={styles.label}>現地決済</h2>
                    <ReservationPaymentOnSite appInstallUrl={appInstallUrl} />
                  </>
                )}

                <div className={styles.pointsBreakdown}>
                  <button type="button" onClick={pointModal.show}>
                    獲得ポイントの内訳
                  </button>
                  <ReservationPointModal
                    isShown={pointModal.isShown}
                    onClose={pointModal.abortHandler}
                    hasCloseButton
                    onCloseButtonClick={pointModal.abortHandler}
                    descriptionType={"priceBased"}
                  />
                </div>
              </div>
            </section>
          )}

          <div>
            <Button
              className={styles.btn}
              styleType="filled"
              colorType="primary"
              type="submit"
              disabled={!isValid}
            >
              次へ
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
